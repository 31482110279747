@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@700&display=swap);
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
/*  background-color: #fff;*/
}
html {
/*	background-color: #f5f8ff;*/
	background-color: #fff;
	
	-moz-user-select: none;
	-webkit-user-select: none; 
	-ms-user-select:none; 
	user-select:none;
	-o-user-select:none; 
}
/*
#root {
	background-color:#f;
	min-height: 100%;
}
*/
.left-login {
	float: left;
	width: 40%;
	background-color: #fff;
	min-height: 96vh;
}
.right-login {
	float: right;
	background-image: url(https://trello-attachments.s3.amazonaws.com/5f0caadc2f38561e0724eb3a/5f524d66dff0832b8299fe67/c1a159b8641d69cc03dc95bbc62e1c33/final-background.png);
	width: 60%;
	height: 96vh;
	background-size: cover;
	background-repeat: no-repeat;
	border-bottom-left-radius: 100px;
	object-fit: cover;
/*	transform: scale(0.4);*/
/*	position: absolute;*/
/*	border-top-left-radius: 100px;*/
	
	
/*	https://static.dribbble.com/users/1543312/screenshots/9835244/media/4ba1e33cd88fff3ba17c0ee75bdf6ca6.png*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*

.newbanner {
	position: fixed;
	bottom: 10;
	height: 45px;
	width: 100%;
	background-color: #000;
	border-style: solid;
	border-radius: 0px;
	border-color: #000;
	padding: 5px 15px;
	color: #fff;
	right: 10px;
	font-size: 12px;
	padding-left: 5px;
	margin-left: auto;
	margin-right: auto;
	display: auto;
	font-weight: 600;
	position: fixed;
  	top: 0px;
	
}
*/
/* Demo.css */

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 800px;
  width: 97%;
}

.file-drop2 {
/*   relatively position the container bc the contents are absolute */
  position: relative;
  height: 20px;
  width: 97%;
}

.vid_username {
	color:#fff;
	position:absolute;
	margin-top:-130px;
	margin-left:20px;
	font-weight: 700;
}
input[type=textarea] {
	
}
.vid_caption {
	color:#fff;
	position:absolute;
	margin-top:-100px;
	margin-left:20px;
	font-weight: 400;
	width: 300px;
}
.vid_hashtag {
	color:#fff;
/*	margin-top:-70px;*/
/*	margin-left:80px;*/
	font-weight: 700;
}


.file-drop > .file-drop-target {
  /* basic styles */
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 97%;
  border-radius: 2px;
  content:"test";

  /* horizontally and vertically center all content */
  display: flex;
  display: -ms-flexbox;

  flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
	
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(250, 252, 255, 1);
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
	
	overflow-y: hidden;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #2C88FF;
  box-shadow: 0 0 13px 3px #2C88FF;
}

.fullbar {
	position: fixed;
	height: 40px;
	background-color: #2C88FF;
	border-style: solid;
	border-radius: 20px;
	border-color: #2C88FF;
	padding: 5px 15px;
	color: #fff;
	right: 10px;
/*	font-size: 12px;*/
	bottom: 10px;
	padding-left: 5px;
}
.fullbar3 {
	display: inline-block;
	height: 25px;
	background-color: #2C88FF;
/*	background-color: rgba(0, 0, 0, 0.04);*/
	border-style: none;
	border-radius: 20px;
/*	padding: 2px 10px;*/
	color: #fff;
	padding-left: 5px;
	margin-left: 10px;
	text-align:center;
	margin-right: 20px;
	font-size: 13px;
}

#shoppable {
	color: white;
	text-decoration: none;
	font-weight: 500;
/*	text-align:center;*/
}

.exitthing {
	bottom: 50px;
	margin-left: auto;
	margin-right: auto;
	z-index: 9999;
	right: 10px;
	display: inline-block;
	font-size: 150px;
	color: #b8b8b8;
}

.shoppablediv {
	display: inline-block;
	height: 40px;
	background-color: #2C88FF;
	border-style: none;
	border-radius: 20px;
	padding: 2px 10px;
	color: #fff;
	padding-left: 5px;
	text-align:center;
	position: fixed;
	bottom: 10px;
	margin-left: auto;
	margin-right: auto;
	z-index: 998;
	right: 10px;
/*	left: 50%;*/
}

.fullbar5 {
	display: inline-block;
	height: 60px;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.04);
	border-style: none;
	border-radius: 10px;
	padding: 2px 10px;
	color: #fff;
/*	padding-left: 5px;*/
/*	margin-left: 10px;*/
	text-align:center;
/*	margin-right: 20px;*/
}
.fullbar5:hover {
	background-color: #E2E6EA;
	text-decoration: none;
}




.fullbar2 {
	display: inline-block;
	height: 40px;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.04);
	border-style: none;
	border-radius: 20px;
	padding: 2px 10px;
	color: #fff;
	padding-left: 5px;
	margin-left: 10px;
	text-align:center;
	margin-right: 20px;
}
.fullbar2:hover {
	background-color: #E2E6EA;
	text-decoration: none;
}

#previewfeedbtn {
	color: black;
	text-decoration: none;
}

#previewfeedbtn2 {
	color: white;
	text-decoration: none;
}


#upgradenow {
	color:#fff;
}


/*NAVBAR CSS*/
sidebar {
	z-index: 1000;
	position: absolute;
}
.register_div {
	margin-left: 17%;
}
.register_div h1 {
	font-family: sans-serif;
	font-weight: 600;
	color:#2D303A;
	font-size: 27px;
}
.register_div h2 {
	font-family: sans-serif;
	font-weight: 500;
	color:#2D303A;
	font-size: 20px;
	margin-top:15px;
}

.register_div h3 {
	font-size: 12px;
	color: grey;
	margin-top: 15px;
}
.input_title {
	font-size: 14px;
	font-weight: 600;
	color: dimgrey;
	padding-bottom: 0px;
	margin-bottom: 0px;
}
.register_div input {
	background-color: transparent;
	border-style: solid;
	border-radius: 6px;
	border-width: 1px;
	border-color: lightgrey;
	width: 300px !important;
	height: 55px !important;
	padding-left: 15px;
	
	
	
/*
	padding: 12px 40px 12px 5px;
	width: 300px !important;
	font-size: 17px;
	color: #9b9bae !important;
	border-bottom-style: solid;
	outline: none !important;
	background-color: white;
	border-top-style: none;
	border-right-style: none;
	border-left-style: none;
	border-bottom-color: #f6f6f6;
	border-radius: 1px;
	border-bottom-width: 1px;
	position: relative;
	top: 3px;
*/
	
}
.register_div input:focus {
	border-color: #2c88ff;

}
.register_div select:focus {
	border-color: #2c88ff;
	
}
.register_div select {
	background-color: transparent;
	border-style: solid;
	border-radius: 6px;
	border-width: 1px;
	border-color: lightgrey;
	width: 630px !important;
	height: 60px !important;
	padding-left: 20px;
	color: grey;
	margin-top: 4px;
	padding-right: 30px !important;

}
select:focus {
	outline:0;
}


.full_inputs input{
	width: 630px !important;
	margin-top: 10px;
	height: 60px !important;
	
}
#required_span {
	color:#5356ea;
}
.half_inputs {
	display: inline-block;
	margin-top: 10px;
	height: 60px !important;
}
.register_btn {
	background-color: #2C88FF !important;
	border-style: solid;
	border-radius: 10px;
	border-color: transparent;
	color: #fff;
	width: 630px;
	height: 60px !important;
	font-weight: 600;
}
.register_btn:hover {
	background-color: #0F78FF !important;
}
.rightside {
	background-color: #f7f9fc;
	width: 25%;
	height: 100vh;
	position: fixed;
	right:0;
	border-bottom-left-radius: 100px;
}

.rightside h1 {
	font-size: 100%;
	font-family:inherit;
	font-weight: 600;
	width: 90%;
	line-height: 2em;
	text-align: left;
	margin-top: 100px;
}
.rightside h2 {
	font-size: 15px;
	font-family:inherit;
	font-weight: 300;
	width: 90%;
	line-height: 2em;
	text-align: left;
}
.nav {
	background-color: #f8f8f8;
	height: 50px;
	width: 100%;
	z-index: 2;
	position: absolute;
}
#company_icon {
	margin-right: 400px !important;
}
.nav-item .nav-link {
    display: inline-block;
    color: #343a40;
    text-decoration: none;
	cursor: pointer;
;}
#plan  {
	padding-left: 5px !important;
	font-size: 13px;
	color: #acacbc;
}
.nav-item2 {
	right:0 !important;
	
}
#navlogout {
	right:0 !important;
	position: absolute !important;
	margin-right: 60px !important;
}
.testbutton {
	position: relative !important;

}
.badone {
	pointer-events: none;
}

.modal-backdrop.show {
    opacity: 0.2 !important;
}



.midmodal {
	max-width: 65% !important;
}

.modal-mid {
	vertical-align: middle !important;
	max-width: 60% !important;
}

.modal-sm {
	vertical-align: middle !important;
	max-width: 70% !important;
}

.modal-sm2 {
	vertical-align: middle !important;
	min-width: 1200px !important;
}

@media (max-device-width: 425px){
	.modal-lg {
/*	vertical-align: middle !important;*/
	max-width: 100% !important;
	}
}

.modal-content {border: 0px solid rgba(0,0,0,0) !important;}

.widemodal {
	max-width: 70% !important;
}

.widemodal2 {
	min-width: 700px !important;
}

#testy .modal-content{
/*    background-color: #2c88ff !important;*/
	background-image: url("https://www.shoptik.co/images/bg-straight.png") !important;
	background-repeat: no-repeat;
	background-size: cover;
}
/*
.modal-content {
   background-color: #2c88ff !important;
}
*/

.paybtn2 {
    padding: 10px 30px;
    background-color: #2c88ff;
    border-style: solid;
    border-color: #2c88ff;
    border-radius: 3px;
    font-size: 13px;
    color: #fff;
    transition: all .2s;
}

.paybtn2:hover {
    padding: 10px 30px;
    background-color: transparent;
    border-style: solid;
    border-color: #2c88ff;
    border-radius: 3px;
    font-size: 13px;
    color: #2c88ff;
    transition: all .2s;
}

/*
.navbar-custom {
    padding: 14px 0px;
    width: 100%;
    border-radius: 0px;
    z-index: 999;
    margin-bottom: 0px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    background-color: transparent
}
*/

    .navbar-custom {
        margin-top: 0px;
        background-color: #fff !important;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
        color: #fff !important
    }

.navbar-custom .logo .logo-dark {
    display: none
}

.navbar-custom .logo .ligo-light {
    display: inline-block
}

.navbar-custom .navbar-nav li a {
    line-height: 26px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    font-weight: 600;
    transition: all 0.3s;
    background-color: transparent !important;
    padding: 6px 0;
    margin: 0 20px;
	right:0 !important;
}

.navbar-custom .navbar-nav li .nav-link {
    padding-right: 0px;
    padding-left: 0px
}

.navbar-custom .navbar-nav li.active a,
.navbar-custom .navbar-nav li a:hover,
.navbar-custom .navbar-nav li a:active {
    color: #fff !important
}

.navbar-custom .navbar-toggles {
    padding: .25rem .75rem;
    font-size: 18px;
    background: 0 0;
    border: 1px solid transparent;
    color: #fff;
    outline: 0
}

.navbar-toggler {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: #fff
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    background-color: transparent;
    border-color: #5e62ff
}

.menu-toggle {
    padding: 4.5px 10px !important
}

.menu-toggle span {
    line-height: 27px
}

.nav-sticky.navbar-custom {
    margin-top: 0px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    color: #000 !important
}

.nav-sticky.navbar-custom .navbar-toggles {
    padding: .25rem .75rem;
    border: 1px solid transparent;
    outline: 0
}

.nav-sticky.navbar-custom .navbar-nav {
    margin-top: 0px
}

.nav-sticky.navbar-custom .navbar-nav li a {
    color: #8a8aa0 !important
}

.nav-sticky.navbar-custom .navbar-nav li.active a,
.nav-sticky.navbar-custom .navbar-nav li a:hover,
.nav-sticky.navbar-custom .navbar-nav li a:active {
    color: #5e62ff !important
}

.nav-sticky .logo .logo-dark {
    display: inline-block !important
}

.nav-sticky .logo .logo-light {
    display: none !important
}

.navbar-toggler:focus {
    outline: none
}

.navbar-light .navbar-nav li a {
    color: #8a8aa0 !important
}

.navbar-light .navbar-nav li.active a,
.navbar-light .navbar-nav li a:hover,
.navbar-light .navbar-nav li a:active {
    color: #5e62ff !important
}

/*
@media (min-width: 200px) and (max-width: 768px) {

   
    .navbar-custom {
        margin-top: 0px;
        background-color: #fff !important;
        -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
        color: #fff !important
    }

    .navbar-custom .navbar-nav {
        margin-top: 0px
    }

    .navbar-custom .navbar-nav li a {
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        color: #9b9bae !important;
        margin: 0px
    }

    .navbar-custom .navbar-nav li.active a {
        border-color: transparent
    }

    .navbar-custom .navbar-nav li.active a,
    .navbar-custom .navbar-nav li a:hover,
    .navbar-custom .navbar-nav li a:active {
        color: #5e62ff !important
    }

    .navbar-custom > .container {
        width: 90%
    }

    .navbar-custom .logo .logo-dark {
        display: inline-block !important
    }

    .navbar-custom .logo .logo-light {
        display: none !important
    }

    .navbar-custom.navbar-light .navbar-nav li.active a,
    .navbar-custom.navbar-light .navbar-nav li a:hover,
    .navbar-custom.navbar-light .navbar-nav li a:active {
        color: #343a40 !important
    }

    .navbar-toggler {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: #343a40
    }

    .navbar-collapse {
        padding: 0 20px
    }

    .navbar-light .nav-item .nav-link:after {
        background: #343a40
    }
}
*/

/*
@media (min-width: 769px) and (max-width: 1024px) {
    .nav-dark .navbar-nav li a {
        color: #9090a5 !important
    }

    .nav-dark .navbar-nav li.active a,
    .nav-dark .navbar-nav li a:hover,
    .nav-dark .navbar-nav li a:active {
        color: #5e62ff !important
    }
}
*/



/*NAVBAR CSS END*/

















.username {
}
.account_stats {
	color: black;
	font-family: 'Rubik', sans-serif;
/*
	font-weight: 700;
	font-size: `18px;
*/
}
span {
	padding: 0px;
	width: 33.33%
}
.stat {
	display: inline-block;
	padding: 20px;
}
.stat h6 {
	font-size: 12px;
	color: grey;
	font-family: sans-serif;
	font-weight: 100;
}

.shop_items {
	text-align: center;
}

#shop_img {
	width: 100px;
	height: 170px;
	border-style: solid;
	border-width: 0.5px;
	border-color: lightgrey;
	border-radius: 5%;
	object-fit: cover;
}

#shop_text_embed {
	color: #000;
	font-size: 15px;
	font-family: sans-serif;
	font-weight: 600;
}

#shop_text {
	color: #000;
	font-size: 13px;
	font-family: sans-serif;
	font-weight: 600;
	text-align: left;
	margin-left: 8%;
	margin-top: 2%;
}
#price {
	font-weight: 400;
	text-align: left;
	color: grey;
}
#price2 {
	font-weight: 400;
	color: grey;
	align-content: flex-start;
}

#gallery_title {
	font-weight: 200;
	font-size: 18px;
	color: black;
	text-align-last: center;
}

video {
	cursor: pointer;
}
/*
.hiddentext {
	display: none;
	position: absolute;
	color: #fff;
	z-index: 999;
  	justify-content: center;
  	align-items: center;
	margin-left: 35%;
	margin-top: 40%;
}
.hiddentext:after {
	content: 'TextText';
}
*/
/*
.overlay {
	transition: all .2s ease-in-out;
}
.overlay:hover {
	filter: brightness(30%);
}
*/
iframe {
  overflow: hidden;
}

@media (max-width: 425px) {
	.register_div {
		width:300px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.half_inputs {
		margin-left: 0px !important;
	}
	.full_inputs input{
		width: 300px !important; 
	}
	.full_inputs select{
		width: 300px !important;
	}
	
	.register_btn {
		width: 300px !important;
	}
	.overlay2 {
		display: none;
/*		user-select: none;*/
		pointer-events: none;
	}
	.image-wrap:hover .overlay2 {
		display: none;
		/*	height: 100%;*/
		cursor: default;
}
	
	.embedtitle {
		margin-top:5%;
	}
	
	.menu-image {
		position: absolute;
		left: 0;
		top: 0;
		padding: 4px;
		width: 100%;
		height: 100%;
		border-radius: 5px;
		float: left;
		object-fit: scale-down !important;
	}

}

@media (max-width: 768px) {
	.rightside {
		display: none;
	}
	.overlay2 {
		display: none;
/*		user-select: none;*/
	}
	
	.menu-image {
		position: absolute;
		left: 0;
		top: 0;
		padding: 4px;
		width: 100%;
		height: 100%;
		border-radius: 5px;
		float: left;
		object-fit: scale-down !important;
/*		object-fit: none !important;*/
	}
}

.carousel {
	width: 100%
}

.image-wrap {
	position: absolute;
	left: 0;
	top: 0;
/*	padding: 4px;*/
	width: 100%;
	display: inline-block;
/*	position: relative;*/
	height:450px;
	border-radius: 5px;
	object-fit: cover;
}

.image-wrap .overlay2 {
	position: absolute;
	top: 0;
	left: 0;
	box-sizing: border-box;
	width: 100%;
	height: 700px;
	color: white;
	font: 20px sans-serif;
	font-weight: 200;
	opacity: 0;
	transition: opacity 0.5s ease;
	background-color: rgba(0, 0, 0, 0.8);
	padding-top: 40%;
	border-radius: 5px;
}
.image-wrap:hover .overlay2 {
	opacity: 0.8;
/*	height: 100%;*/
}
.menu-image {
	position: absolute;
	left: 0;
	top: 0;
	padding: 4px;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	float: left;
	object-fit:cover;
}

.image-wrap:hover:after {
	opacity: 0;
}

/*
.overlay {
	position: relative;
}
.overlay:after {
	content: 'test';
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.6);
	opacity: 0;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
}
.overlay:hover:after {
	opacity: 1;
}
*/
/*
.darkimage:hover .hiddentext{
	display: block;
	color: #fff;
}
.hiddentext:hover .overlay {
	filter: brightness(0.6);
}
.overlay {

	z-index: 999;
	width: 100%;
	height: 100%;
}
.overlay img {
	width: 100%;
	vertical-align: top;
}
.overlay:after {
	z-index: 999;
	content: url("https://www.shoptik.co/images/shoplook2.png");
	color: #fff;
	position: absolute;
	width: 30%;
	top: 0px; left: 0px;
	filter: brightness(1.3);
	opacity: 0;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	text-align: center;
}
.overlay:hover:after {
	opacity: 1;
}
*/

/*
.darkimage:hover > .overlay {
    width:100%;
    height:100%;
    position:absolute;
    background-color:#000;
    opacity:0.5;
    border-radius:30px;
}
*/

.order_num {
	background-color: #f4f4f4;
	padding: 2px;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin-top: -10px;
	margin-left: -15px;
	position: absolute;
	color: #000;
/*	border-color: e;*/
/*	border-style: solid;*/
	border-width: 0.5px;
	z-index: 1;
}



.delete_vid {
	cursor: pointer; 
}
#delete:hover {
	color: lightgrey !important;
}
.delete_vid {
/*	background-color: clear;*/
	z-index: 999;
	padding: 2px;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	margin-top: 5px;
	margin-left: 153px;
	position: absolute;
	color: white;
/*
	border-color: grey;
	border-style: solid;
	border-width: 0.5px;
*/
}

#movearrow:hover {
	color: lightgrey;
	z-index: 999;
}

#movearrow {
	z-index: 999;
}

.check-text {
	color: #67757B;
	margin:20px;
}
.fa-check {
	color: #2C8AF9;
}
/*
.start_btn {
	background-color: #2C88FF !important;
}
*/

.tag_num {
	background-color: #f4f4f4;
	padding: 2px;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin-top: -10px;
	position: absolute;
	color: #000;
	border-color: grey;
	border-style: solid;
	border-width: 0.5px;
}
.video_layout {
	object-fit: cover;
}

/*dashboard styling */
#select_vid {
	width: 100px;
	border-style: solid;
	border-width: 0.5px;
	border-color: lightgrey;
	border-radius: 5%;
	object-fit: cover;
}
#video_stats {
	font-weight: 500;
	color: #000;
	font-size: 14px;
	justify-content: center;
}
.add_video:hover {
	cursor: pointer;
	-webkit-filter: brightness(50%);
	        filter: brightness(50%);
}

.sidebar_overlay {
	background-color: rgba(0,0,0,1);
}
#active_vids {
	z-index: 10;
	border-radius: 5%;
	object-fit: cover;
}
#add_vid_btn {
	margin: 18px;
}
.videos_container {
		margin: 13px;
box-shadow: 0px 0px 46px -20px rgba(0,0,0,0.1);
	
	transition: all 0.4s;
	border-width: 1px;
	border-radius: 15px;
	border-style: solid;
	border-color: rgba(0,0,0,0.03);
}
.active_vid_container {
/*	margin: 10px;*/
	
}
#active_vid_container {
	margin: 10px;
}
.addmodal {
	display: block;
	width: 1200px !important;   /* Occupy the 90% of the screen width */
    padding-left: 0px !important;
}
#add_product_layout {
	width: 200px;
	height: 200px;
	border-style: solid;
	border-width: 0.5px;
	border-color: lightgrey;
	border-radius: 5%;
	object-fit: cover;
}
#add_product_text {
	color: #000;
	font-size: 13px;
	font-family: sans-serif;
	font-weight: 600;
/*	text-align: left;*/
	margin-left: 8%;
	margin-top: 2%;
}
.profile_inputs {
	width: 70% !important;
	padding: 12px 40px 12px 20px;
}
.captextlabel {
	font-size:15px;
	font-weight: 600;
}

.mediaarea {
/*
	border-style:dashed;
	border-color: #DBE4EC;
	border-radius: 2px;
	border-width: 2px;
*/
	height: 200px;
	background-color:transparent;
	width: 300px;
	display: inline-block; 
}
textarea.captionarea {
	border-style:solid;
	border-color: #DBE4EC;
	border-radius: 2px;
	border-width: 2px;
	height: 40px;
	background-color:transparent;
}
textarea.captionarea::-webkit-input-placeholder {
	color:#BBCBD9;
	top:0;
	top:0;
}
textarea.captionarea:-ms-input-placeholder {
	color:#BBCBD9;
	top:0;
	top:0;
}
textarea.captionarea::-ms-input-placeholder {
	color:#BBCBD9;
	top:0;
	top:0;
}
textarea.captionarea::placeholder {
	color:#BBCBD9;
	top:0;
	top:0;
}

textarea {
	padding: 12px 40px 12px 20px;
	width: 100%;
	font-size: 17px;
	color: #9b9bae !important;
	border: none;
	outline: none !important;
	background-color: #f8f8f8;

	border-radius: 1px;
	position: relative;
	top: 3px;
	min-height: 100px;
}

textarea::-webkit-input-placeholder {
	font-size: 14px;
	color: #acacbc;
}

textarea:-ms-input-placeholder {
	font-size: 14px;
	color: #acacbc;
}

textarea::-ms-input-placeholder {
	font-size: 14px;
	color: #acacbc;
}

textarea::placeholder {
	font-size: 14px;
	color: #acacbc;
}

.logininput input{
	padding: 12px 40px 12px 15px !important;

}

input {
	padding: 12px 40px 12px 5px;
	width: 100%;
	font-size: 17px;
	color: #9b9bae !important;
	border-bottom-style: solid;
	outline: none !important;
	background-color: white;
	border-top-style: none;
	border-right-style: none;
	border-left-style: none;
	border-bottom-color: #f6f6f6;
	border-radius: 1px;
	border-bottom-width: 1px;
	position: relative;
	top: 3px;
}
input:active {
	border-bottom-color: #2c88ff;
}
input:focus {
	border-bottom-color: #2c88ff;
}

input::-webkit-input-placeholder {
	font-size: 14px;
	color: #acacbc;
}

input:-ms-input-placeholder {
	font-size: 14px;
	color: #acacbc;
}

input::-ms-input-placeholder {
	font-size: 14px;
	color: #acacbc;
}

input::placeholder {
	font-size: 14px;
	color: #acacbc;
}
.quickaddbtn:hover {
	cursor: pointer;
}
#logo {
	font-size: 35px;
	color: #5356EA;
	margin: 20px;
}


.shrink{
	margin-top: -100px;
transform:scale(0.8);
}

.shrink2{
	margin-top: -100px;
transform:scale(0.5);
	float: left
}


.fadetext {
	
	z-index: 999;
	position: absolute;
	margin-top: -400px;
	margin-left: -120px;
	
opacity: 0;
	
	 -webkit-animation: fadeout 3s ease; 
            animation: fadeout 3s ease;
	
/*
	    -webkit-animation: fadein 3s ease;
       -moz-animation: fadein 3s ease; 
        -ms-animation: fadein 3s ease;
         -o-animation: fadein 3s ease; 
            animation: fadein 3s ease;
*/
	
	opacity: 1;
/*	opacity: 0;*/
}

@keyframes fadeout {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */


@keyframes fadein {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Internet Explorer */

/* Opera < 12.1 */

.prices {
    margin-top: -30px !important;
}

.pricingplans {
    display: inline-block;
    padding: 30px;
}

.priceingbox {
    background-color: #fff;
    height: 400px;
    width: 320px;
    box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.pricetitle {
    color: #3A4153;
    font-size: 20px;
    margin: 10px;
    padding-top: 15px;
}

#underline {
    background-color: #2c88ff;
    width: 30px;
    height: 5px;
    display: block;
    border-radius: 10px;
    margin-top: -20px;
}

.price {
    font-family: sans-serif;
    font-weight: 700;
    font-size: 50px;
    display: inline-block;
	margin-right: -25px !important;
}

.pricetext {
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
}

#underpricetext {
    color: grey;
    opacity: 0.7;
    width: 50%;
    line-height: normal;
    font-size: 14px;
}

.paybtn {
    padding: 10px 30px;
    background-color: transparent;
    border-style: solid;
    border-color: #2c88ff;
    border-radius: 3px;
    font-size: 13px;
    color: #2c88ff;
    transition: all .2s;
}

.paybtn:hover {
    background-color: #2c88ff;
    color: #fff;
}

.sidebar-btn {
	margin-left: -3px;
	border: none;
	background-color: transparent;
	font-size: 15px;
/*	margin-top: 10px;*/
	padding: 5px 10px;
	width: 200px;
	text-align-last: left;
	text-align: -webkit-left;
	border-radius: 5px;
	color: #818E94;
}
.bot {
	position:absolute;
    top:100vh;
    transform:translateY(-125%);
    width:100%;
}

.btn-active {
/*	color: black;*/
	color: #2c88ff;
	background-color: #E9F4FF;
	z-index: 999;
}

.sidebar-btn:hover {
	color: black;
}
.btn-active:hover {
	color: #2c88ff !important;
}
.main {
/*	 should be 110 */
	margin-left: 110px;
}
.feature_description_text {
	color:#2D303A !important;
	font-family: 'Roboto', sans-serif !important;
	letter-spacing: 0.004em !important;
	line-height: 1.3em !important;
	font-size: 28px !important;
/*
	font-weight: 600;
	letter-spacing: 0.1px;
*/
/*
	font-family: sans-serif;
	font-weight: 500;
*/
}
.logininput {
	height: 65px !important;
	width: 500px !important;
	background-color: #fff !important;
	border-style: solid;
	border-radius: 10px;
	border-width: 2px;
	border-color: #f8f8f8;
	
}
/*LOGIN PAGE STYLING*/
.login_container {
	margin-top: 110px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	width: 500px;
}
#welcome {
	font-size: 35px;
	color: #0A0A0A;
	margin-top: 50px;
	font-weight: 300;
	text-align: left;
	text-align-last: left;
}
.custom-file-upload:hover {
	cursor: pointer;
}
#login-title {
	color: #2878FF;
	font-weight: 600;
}
#login-title-desc {
	color:  #9A9C9C;
	letter-spacing: 0.5px;
}
#email, #password, #brand, #tiktok_handle {
	width: 400px;
	height: 50px;
}
#sign_in_btn {
	width: 500px;
	height: 65px;
	border-radius: 10px;
}
#no_acc {
	color: grey;
}
.input_names {
	text-align-last: left;
	margin-left: 14%;
	font-size: 14px;
	margin-bottom: -1px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  background-color: transparent;
  overflow: auto;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
#sidehead {
	color: #828F95 !important;
}
#sidehead:hover {
	color: #2D303A !important;
	cursor: pointer;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary.focus,
.btn.btn-primary:active,
.btn.btn-primary.active {
    background-color: #2C88FF !important;
    border: 1px solid #2C88FF !important;
    box-shadow: 0 3px 10px #2c88ff;
    color: #fff !important
}

.btn.btn-primary {
    background-color: #2C88FF !important;
    border: 1px solid #2C88FF !important;
    color: #fff;
    box-shadow: 0 4px 20px rgba(94, 98, 255, 0.15)
}

#freedemoinput {
    padding: 12px 20px;
    width: 50%;
    font-size: 17px;
    border: none;
    outline: none !important;
    background-color: rgba(251, 251, 251, 0.85);
    box-shadow: 0 2px 10px rgba(52, 58, 64, 0.12);
    border-radius: 3px;
}



.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #5356ea;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.register_box {
	float: left;
	margin-left: 300px;
	border: none;
	outline: none !important;
	background-color: #fff;
	box-shadow: 0 2px 10px rgba(52, 58, 64, 0.12);
	width: 550px;
/*	height: 650px;*/
/*	padding: 10px 20px;*/
	border-radius: 8px;
}

/*BOX CSS*/
.box_container {
/*	color: #323B43;*/
	color: #000;
	border: none;
	outline: none !important;
	background-color: #fff;
/*
	-webkit-box-shadow: 0 2px 10px rgba(52, 58, 64, 0.12);
	box-shadow: 0 2px 10px rgba(52, 58, 64, 0.12);
*/
/*	width: 350px;*/
	width: 164.5px;
	height: 140px;
	padding: 10px 20px;
	display: inline-block;
	margin-right: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 8px;
box-shadow: 0px 0px 46px -20px rgba(0,0,0,0.1);
	
	transition: all 0.4s;
	border-width: 1px;
	border-radius: 15px;
	border-style: solid;
	border-color: rgba(0,0,0,0.03);

}
.box_container h1 {
	margin-top: 7px;
/*	color: #5356EA;*/
	font-size: 270%;
}
.box_container h4 {
	color: #aeaeb0;
	font-size: 100%;
}
.box_container p {
	margin-top: 8px;
	font-size: 80%;
}
/*Largebox CSS*/
.graphbox_container {
	border: none;
	outline: none !important;
	background-color: #fff;
box-shadow: 0px 0px 46px -20px rgba(0,0,0,0.1);
	transition: all 0.4s;
	
	
/*
	-webkit-box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.2);
	-moz-box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.2);
	box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.2);
*/
/*	width: 350px;*/
/*	width: 47.5%;*/
	height: 490px;
	padding: 15px 25px;
	display: inline-block;
	margin-right: 30px;
	margin-top: 10px;
/*	margin-bottom: 30px;*/
	border-radius: 15px;
	border-width: 1px;
	border-radius: 15px;
	border-style: solid;
	border-color: rgba(0,0,0,0.03);

}
.seeall {
}
.seeall:hover {
	cursor: pointer;
	text-decoration: none;
}
.graphbox_container h1 {
	margin-top: 7px;
/*	color: #5356EA;*/
	font-size: 210%;
	font-weight: 600;
}
.graphbox_container h4 {
	color: #aeaeb0;
	font-size: 100%;
}
.graphbox_container p {
	margin-top: 8px;
	font-size: 80%;
}


/*Largebox CSS*/
.largestbox_container {
	border: none;
	outline: none !important;
	background-color: #fff;
	box-shadow: 0 2px 10px rgba(52, 58, 64, 0.12);
/*
	-webkit-box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.75);
box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.75);
*/
/*	width: 350px;*/
	width: 45%;
	height: 180px;
	padding: 15px 25px;
	display: inline-block;
	margin-right: 30px;
	margin-top: 10px;
	margin-bottom: 30px;
	border-radius: 15px;

}
.largestbox_container h1 {
	margin-top: 7px;
/*	color: #5356EA;*/
	font-size: 210%;
	font-weight: 600;
}
.largestbox_container h4 {
	color: #aeaeb0;
	font-size: 100%;
}
.largestbox_container p {
	margin-top: 8px;
	font-size: 80%;
}
/*comparebox CSS*/
.comparebox_container {
	border: none;
	outline: none !important;
	background-color: #fff;
box-shadow: 0px 0px 46px -20px rgba(0,0,0,0.1);
	
	transition: all 0.4s;
	border-width: 1px;
	border-radius: 15px;
	border-style: solid;
	border-color: rgba(0,0,0,0.03);
/*
	-webkit-box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.2);
-moz-box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.2);
box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.2);
*/
/*	width: 350px;*/
/*	width: 30%;*/
	height: 300px;
	padding: 15px 25px;
	display: inline-block;
	margin-right: 30px;
	margin-top: 10px;
	margin-bottom: 30px;
	border-radius: 15px;
}
.comparebox_container h1 {
	margin-top: 7px;
/*	color: #5356EA;*/
	font-size: 210%;
	font-weight: 600;
}
.comparebox_container h4 {
	color: #aeaeb0;
	font-size: 100%;
}
.comparebox_container p {
	margin-top: 8px;
	font-size: 80%;
}

/*comparebox CSS*/
.rankbox_container {
	border: none;
	outline: none !important;
	background-color: #fff;
box-shadow: 0px 0px 46px -20px rgba(0,0,0,0.1);
	
	transition: all 0.4s;
	border-width: 1px;
	border-radius: 15px;
	border-style: solid;
	border-color: rgba(0,0,0,0.03);
/*
	-webkit-box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.2);
-moz-box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.2);
box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.2);
*/
/*	width: 350px;*/
/*	width: 30%;*/
/*	height: 300px;*/
	padding: 15px 25px;
	display: inline-block;
	margin-right: 30px;
	margin-top: 10px;
	margin-bottom: 30px;
	border-radius: 15px;
}
.rankbox_container h1 {
	margin-top: 7px;
/*	color: #5356EA;*/
	font-size: 210%;
	font-weight: 600;
}
.vertcent {
/*	 vertical-align: bottom;*/
	margin: auto;
}
.rankbox_container h4 {
	color: #aeaeb0;
	font-size: 100%;
}
.rankbox_container p {
	margin-top: 8px;
	font-size: 80%;
}
.analysis_hover {
	width: 100%;
	padding: 5px;
}
.analysis_hover:hover {
	background-color: #fafafa;
	cursor: pointer;
	border-radius: 10px;
}

/*Largebox CSS*/
.largebox_container:hover, .graphbox_container:hover, .table_shadow:hover, .comparebox_container:hover, .rankbox_container:hover, .videos_container:hover {
box-shadow: 0px 21px 29px -10px rgba(0,0,0,0.05);
}



.largebox_container {
	transition: all 0.4s;
	outline: none !important;
	background-color: #fff;
box-shadow: 0px 0px 46px -20px rgba(0,0,0,0.1);
/*
	-webkit-box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.75);
box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.75);
*/
/*	width: 350px;*/
/*	width: 30%;*/
	height: 185px;
	padding: 15px 25px;
	display: inline-block;
	margin-right: 30px;
	margin-top: 10px;
	margin-bottom: 30px;
	border-width: 1px;
	border-radius: 15px;
	border-style: solid;
	border-color: rgba(0,0,0,0.03);
}
.largebox_container h1 {
	margin-top: 7px;
/*	color: #5356EA;*/
	font-size: 210%;
	font-weight: 600;
}
.largebox_container h4 {
	color: #aeaeb0;
	font-size: 100%;
}
.largebox_container p {
	margin-top: 8px;
	font-size: 80%;
}

.main h3 {
	color:#2D303A !important;
/*	font-family: 'Roboto', sans-serif !important;*/
	letter-spacing: 0.004em !important;
	line-height: 1.3em !important;
	font-size: 26px !important;
	font-weight: 600 !important;
}

.backshadow {
	background-color: #fff;
	box-shadow: 10px 10px 300px -72px rgba(0,0,0,0.75);
}


/*Largebox CSS*/
.smallbox_container {
	border: none;
	outline: none !important;
	background-color: #fff;
box-shadow: 10px 10px 300px -50px rgba(0,0,0,0.85);
/*	width: 350px;*/
/*	width: 30%;*/
	height: 125px;
	padding: 10px 10px;
	display: inline-block;
	margin: 15px;
	border-radius: 15px;

}
.smallbox_container h1 {
	margin-top: 4px;
/*	color: #5356EA;*/
	font-size: 130%;
	font-weight: 600;
}
.smallbox_container h4 {
	color: #aeaeb0;
	font-size: 100%;
}
.smallbox_container p {
	margin-top: 4px;
	font-size: 50%;
}
.change-arrow {
	font-size: 16px !important;
	color: darkslategrey;
	font-weight: 700;
}
.plus {
}
.influencer {
/*
	border-top-style: solid;
	border-color: red;
	border-width: 30px;
	border-left-color: red;
  	border-top-left-radius: 100px;
*/
}
.influencerpill {
	transition: all 0.2s;
}
.influencerpill:hover {
	cursor: pointer;
	transform: scale(0.98);
}
.influencer-price {
	background-color: #3FE06A;
/*	width: 30%;*/
	position: absolute;
	right: 0;
	min-width: 80px;
	height: 32px;
	margin-right: 5px;
	border-style: solid;
	border-top-right-radius: 10px;
	border-color: transparent;
	border-bottom-left-radius: 10px;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
}
.follcount {
	font-size: 16px;
	color: #2c88ff;
	margin-top: 0px;
	font-weight: 600;
}

.follcount2 {
	font-size: 14px;
	color: #2c88ff;
	margin-top: 0px;
	font-weight: 600;
}
/* Bottom left text */
.bottom-left-username {
  position: absolute;
  bottom: 11px;
  left: 26px;
  color: #2D313A;
/*	#2c88ff*/
  font-weight: 500;
	text-align: left;
	text-align-last: left;
/*background-color: blue;*/
	font-size: 20px;
}

.bottom-left-username2 {
  position: absolute;
  bottom: 25px;
  left: 26px;
  color: #2D313A;
/*	#2c88ff*/
/*  font-weight: 500;*/
	text-align: left;
	text-align-last: left;
/*background-color: blue;*/
	font-size: 13px;
}

.bottom-left-username3 {
  position: absolute;
  bottom: 25px;
  left: 16px;
  color: #2D313A;
/*	#2c88ff*/
/*  font-weight: 500;*/
	text-align: left;
	text-align-last: left;
/*background-color: blue;*/
	font-size: 12px;
}



button:focus, button:active {
    outline-color: transparent !important;
}

.table_shadow {
box-shadow: 0px 0px 46px -20px rgba(0,0,0,0.1);
	
	transition: all 0.4s;
	border-width: 1px;
	border-radius: 15px;
	border-style: solid;
	border-color: rgba(0,0,0,0.03);
	
	
	border-radius: 8px;
	padding: 20px;
	
}

.btn-tiktokbutton {
	color: #fff !important;
	background-color: #F72C55 !important;
	font-weight: 600 !important;
}

.btn-tag_btn {
/*	background-color: #5356EA !important;*/
	color: #fff !important;
	background-color: #2C88FF !important;
}
.btn-tag_btn:hover {
/*	background-color: #4a4dd2 !important;*/
	background-color: #0a75fc !important;
}
.btn-recent_vid_add {
	background-color: #2C88FF !important;
	color: #fff !important;
}
.btn-recent_vid_add:hover {
	background-color: #0a75fc !important;
}
.btn-sign_in {
	background-color: #2C88FF !important;
	color: #fff !important;
}
.btn-sign_in:hover {
	background-color: #0f78ff !important;
}
.login_text {
	color: #9A9C9C;
	font-size: 14px;
	float: right;
	margin-top: 14px;
}
.login_text:hover {
	color: #9A9C9C;
}
.xanalytics {
	background-color: transparent;
/*	color:  #EE3737;*/
	color: lightgrey;
	border: none;
	margin-left: 0px;
	font-size: 20px;
	float: right;
	margin-top:-14px;
}
.xanalytics:hover {
	cursor: pointer;
	color: #F05A5B;
}

.xanalytics2 {
	background-image: url("https://trello-attachments.s3.amazonaws.com/5f0caadc2f38561e0724eb3a/5f57de4b78930230867ec4d1/6611378912dda0bf52bc797637a194d0/plus2.png");
/*
	background-color: transparent;
	color:  #2C88FF;
	border: none;
	margin-left: 10px;
	margin-top: -10px;
	font-size: 25px;
*/
/*	float: right;*/
}
.xanalytics2:hover {
	cursor: pointer;
	color: #2C88FF;
}

.select {
	float: right;
    padding-right: 54px;
    font-family: 'Open Sans', sans-serif !important;
    color: black;
    width: 350px;
    height: 40px;
    font-size: 15px;
}

#date_select {
	width: 700px !important;
}
/*FEED*/

.fa-caret-down{
  transform: rotate(0deg);
  transition: transform 0.2s linear;

}

.fa-caret-down.open{
  transform: rotate(-180deg);
  transition: transform 0.2s linear;

}

.fa-angle-down{
  transform: rotate(0deg);
  transition: transform 0.2s linear;
  left: 190px;
	position:fixed;
}

.fa-angle-down.open{
  transform: rotate(-180deg);
  transition: transform 0.2s linear;
  left: 190px;
	position:fixed;
}
.bottom-side {
	position: absolute; bottom: 0px; 
}



#bio {
	font-family: sans-serif;
	font-weight: 400;
	color: #000;
	font-size: 13px;
}
#link_bio {
	color: grey;
	font-weight: 600;
	font-size: 16px;
	margin-top: -55px;
	position: absolute;
	z-index: 999;
	position:fixed;
}
#profile_image {
	border-radius: 50%;
	width: 35px;
	float: right;
	margin-top: -65px;
	position: absolute;
	z-index: 999;
	position:fixed;
	right: 0;
	margin-right: 150px;
	cursor: pointer;
}

#upgrade_button {
	width: 35px;
	float: right;
	margin-top: -65px !important;
	position: absolute;
	z-index: 999;
	position:fixed;
	right: 0;
	margin-right: 220px !important;
	cursor: pointer;
	background-color: #36C635 !important;
	
}

#linkbio {
	color: black;
	font-weight: 700;
	font-size: 13px;
}

hr {
	opacity: 0.3;	
}
.fa-selected {
	opacity: 1;
	padding: 0px 50px;
}
.fa-unselected {
	opacity: 0.15;
	padding: 0px 50px;
}
.bag_icon {
/*	position: absolute;*/
	z-index: 999;
	position: absolute;
/*
	padding: 2px;
	width: 30px;
	height: 30px;
	margin-top: -10px;
	position: absolute;
	color: #fff;
*/
}
.register_right {
	background-color: red;
/*	margin-right: 150px;*/
/*	width: 50%;*/
	text-align-last: left;
	width: 300px;
	margin-left: 500px;
}
#right_text {
	font-family: sans-serif;
	font-weight: 800;
	font-size: 25px;
	color: #5356ea;
	margin-left: 30px;
}
#right_bottom {
	text-align-last: left;
	margin-left: 30px;
}

::-webkit-scrollbar {
    display: none;
}
.guide {
	color: #5356EA;
	font-family: sans-serif;
	font-size: 25px;
	font-weight: 800;
}
.guidep {
	width: 500px;
}
.def {
	color: #5356EA;
	font-weight: 800;
}
.vid_bag {
	position: absolute;
	color: #fff;
	margin-top: 180px;
	margin-left: 10px;
	font-size: 20px;
}
.mobile_error {
	display: none;
}
#moreinfo:hover {
	cursor:default;
}
.add_prod_form {
	float: right;
	width: 50%;
}
.library_img:hover {
	cursor: pointer;
	-webkit-filter: brightness(50%);
	        filter: brightness(50%);	
}
@media (max-device-width: 768px) and (orientation: portrait) {
	.nav {
		display: none;
		background-color: #fff;
	}
	.mobile_error {
		display: block;
		background-color: #fff;
		font-family: sans-serif;
		font-weight: 500;
		color: #005e9f;
		padding:0;
		margin:0;
	}
	.login_container {
		display: none;
	}
}
@media (max-device-width: 768px) and (orientation: landscape) {
	.nav {
		display: none;
		background-color: #fff;
	}
	.mobile_error {
		display: block;
		background-color: #fff;
		font-family: sans-serif;
		font-weight: 500;
		color: #005e9f;
		padding:0;
		margin:0;
	}
	.login_container {
		display: none;
	}
}
.newloader {
	margin-left: auto;
	margin-right: auto;
	
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #2C88FF;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
.sidebar {
            height: 100%;
            width: 75px;
            position: fixed;
            z-index: 1000;
            top: 0;
            left: 0;
            background-color: #fff;
            overflow-x: hidden;
            transition: 0.2s;
            padding-top: 60px;
            white-space: nowrap;
			border-right-style: solid;
			border-right-color: #f4f4f4;
			border-right-width: 0.5px;
			
        }
        
        .sidebar a {
            padding: 8px 8px 8px 32px;
            text-decoration: none;
            font-size: 25px;
            color: #818181;
            display: block;
            transition: 0.3s;
			
        }
        
        .sidebar a:hover {
            color: #f1f1f1;
        }
        
        .sidebar .closebtn {
            position: absolute;
            top: 0;
/*            right: 25px;*/
            font-size: 36px;
/*            margin-left: 50px;*/
        }
        
        .material-icons,
        .icon-text {
            vertical-align: middle;
        }
        
        .material-icons {
            padding-bottom: 3px;
        }
        
/*
        .main {
            transition: margin-left 5s;
            padding: 16px;
            margin-left: 100px;
        }
*/
        /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
        
        @media screen and (max-height: 450px) {
            .sidebar {
                padding-top: 15px;
            }
            .sidebar a {
                font-size: 18px;
            }
        }

.vl {
  border-left: 2px solid grey;
  height: 500px;
}

.tail-datetime-calendar {
/*  width: 40%;*/

  margin: 10px;
  padding: 0;
  display: block;
  overflow: hidden;
  position: absolute;
  border-collapse: separate;
  font-family: "Open Sans", Calibri, Arial, sans-serif;
  background-color: #ffffff;
	
/*
	
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
  border-radius: 3px;
  -webkit-border-radius: 3px;
*/
}
.tail-datetime-calendar:after {
  clear: both;
  content: "";
  display: block;
  font-size: 0;
  visibility: hidden;
}
.tail-datetime-calendar.calendar-static {
  margin-left: auto;
  margin-right: auto;
}
.tail-datetime-calendar .calendar-navi {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  background-color: #2C88FF;
  border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
}
.tail-datetime-calendar .calendar-navi span {
  cursor: pointer;
  color: #fff;
  margin: 0;
  padding: 0;
  display: table-cell;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.15);
  background-repeat: no-repeat;
  background-position: center center;
}
.tail-datetime-calendar .calendar-navi span:first-child,
.tail-datetime-calendar .calendar-navi span:last-child {
  width: 35px;
  padding: 0 0 5px 0;
  font-size: 22px;
}
.tail-datetime-calendar .calendar-navi span:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
.tail-datetime-calendar .calendar-navi span.button-prev {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
    TYgMkwwIDhsNiA2VjJ6Ii8+PC9zdmc+");
}
.tail-datetime-calendar .calendar-navi span.button-next {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\
    TAgMTRsNi02LTYtNnYxMnoiLz48L3N2Zz4=");
}
.tail-datetime-calendar .calendar-navi span.button-check {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
    SJNMTIgNWwtOCA4LTQtNCAxLjUtMS41TDQgMTBsNi41LTYuNUwxMiA1eiIvPjwvc3ZnPg==");
}
.tail-datetime-calendar .calendar-navi span.button-close {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
    SJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4y\
    NWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
}
.tail-datetime-calendar .calendar-date {
  margin: 0;
  padding: 0;
  display: block;
}
.tail-datetime-calendar .calendar-date table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
.tail-datetime-calendar .calendar-date table thead tr > *,
.tail-datetime-calendar .calendar-date table tbody tr > * {
  color: grey;
  width: 35px;
  height: 100px;
  padding: 0;
  font-size: 14px;
  text-align: center;
  font-weight: heavy;
  line-height: 35px;
  border: 0;
  background-color: #fff;
}
.tail-datetime-calendar .calendar-date table tbody tr > * {
  color: #334455;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  background-color: #ffffff;
	border-top-style: solid;
	border-top-color: #f4f4f4;
	border-top-width: 1px;
	width: "90%";
/*	border-top-width: 80%;*/
	
}
.tail-datetime-calendar .calendar-date table tbody tr > * > span {
  color: inherit;
  z-index: 10;
  position: relative;
	
}
/* Select a Day */
.tail-datetime-calendar .calendar-date table tbody tr > *::after {
/*
  top: 3px;
  left: 52px;
  width: 29px;
  height: 29px;
  content: "";
  display: block;
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
*/
/*  border-radius: 50%;*/
	
/*
	content:"";
	display: block;
	margin: 0 auto;
	
	width: 80%;
	padding-top: 20px;
	border-top: 1px solid #DBE4EC;
*/
}
.medialibrarycal {
	float: left !important;
	display: inline-block !important;
	width: 300px !important;
	border-right-style: solid;
	border-right-width: 1px;
	border-right-color: #DBE4EC;
	padding-right: 10px;
	background-color: #fff;
	z-index: 999 !important;
}
.calendar-sect {
	width:65% !important;
	display: inline-block !important;
	margin-left: -23%;
	z-index: 10 !important;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.today {
  color: #2C88FF;
  background-color: #EEF1F6;
  border-top-style: solid;
  border-top-color: #2c88ff;
  border-top-width: .1px;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.today:hover {
  color: #2C88FF;
  background-color: #EEF1F6;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.today:after {
  border-color: #2C88FF;
}

.tail-datetime-calendar .calendar-date table tbody tr > * {
	vertical-align: top;
	padding-top:5px;
	
}

.tail-datetime-calendar .calendar-date table tbody tr > *:hover {
  color: #2c88ff;
  background-color: #fafafa;

}
.tail-datetime-calendar .calendar-date table tbody tr > *:hover:after {
  border-color: #2C88FF;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.empty,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable {
  color: #f4f4f4;
  cursor: not-allowed;
  background-color: #fafafa;
  opacity: 1;
	
}
.tail-datetime-calendar .calendar-date table tbody tr > *.empty:hover:after,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable:hover:after {
  content: "";
  display: none;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.current span {
  color: #fff;
}
.tail-datetime-calendar .calendar-date table tbody tr > *.current:after {
  border-color: #3296c8;
  background-color: #3296c8;
}
/* Select A Month */
.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * {
  width: 81.66666666666667px;
  padding: 5px;
  line-height: 25px;
}
.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * span {
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail-datetime-calendar
  .calendar-date
  table.calendar-month
  tbody
  tr
  > *:hover
  span {
  border-color: #d0d0d0;
  box-shadow: 0 1px 0 0 #efefef;
  -webkit-box-shadow: 0 1px 0 0 #efefef;
}

.vl {
  border-left: 0.5px solid lightgrey;
  height: 800px;
	margin-left: 18%;
}

/* Select a Time */
.tail-datetime-calendar .calendar-time {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  text-align: center;
  background-color: #ffffff;
  border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
}
.calendar-td {
/*	background-color: #000 !important;*/
}
.calendar-td:hover {
	background-color: #fafafa;
}
.tail-datetime-calendar .calendar-date + .calendar-time {
  border-top: 1px solid #d0d0d0;
  background-color: #f8f8f8;
}
.tail-datetime-calendar .calendar-time .calendar-field {
  width: 33.3333333333%;
  padding: 10px 0;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: top;
}
.tail-datetime-calendar .calendar-time .calendar-field:first-child {
  text-align: right;
}
.tail-datetime-calendar .calendar-time .calendar-field:first-child:after {
  top: 12px;
  right: -10px;
  content: ":";
  position: absolute;
}
.tail-datetime-calendar .calendar-time .calendar-field:last-child {
  text-align: left;
}
.tail-datetime-calendar .calendar-time .calendar-field:last-child:after {
  top: 12px;
  left: -10px;
  content: ":";
  position: absolute;
}
.tail-datetime-calendar .calendar-time .calendar-field input[type="number"] {
  width: 100%;
  max-width: 50px;
  margin: 0;
  padding: 3px 4px;
  font-size: 12px;
  line-height: 16px;
/*  text-align: top;*/
  border-width: 1px;
  border-style: solid;
  border-color: #d0d0d0;
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}

.tail-datetime-calendar
  .calendar-time
  .calendar-field
  input[type="number"]:hover {
  border-color: #a0a0a0;
}
.tail-datetime-calendar
  .calendar-time
  .calendar-field
  input[type="number"]:focus {
  border-color: #3296c8;
}
.tail-datetime-calendar .calendar-time .calendar-field label {
  color: #778899;
  margin: 5px 0 0 0;
  padding: 0;
  display: block;
  font-size: 12px;
  line-height: 12px;
}
/* @end tail.DateTime */


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

